import { Fragment } from 'react'

import { AdPos, Ads } from '@scm/ads'
import { EventType, GTM, usePaidContentListLoaded } from '@scm/tag-manager'
import { Button } from '@scm/ui-component-button'
import { Bem } from '@scm/ui-core'
import {
  BaseContentPreviewProps,
  ContentPreview,
} from '@scm/ui-module-content-preview'

import { HighlightContentsProps } from './type'

const highlightContents = Bem('HighlightContents')

const MODULE_UID = 'module_three_column_highlight'

export const HighlightContents = ({
  data,
  isNativeAdEnabled = false,
  moduleLocation,
  title,
  dateDisplay = 'show',
}: HighlightContentsProps) => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  const adItems: (AdPos | undefined)[] = data.map(column => {
    const items = column.contents.filter(item => item.type === 'ad')
    if (items.length === 0) return undefined
    if (items[0].type !== 'ad') return undefined
    return items[0].data.pos
  })

  const isAdRender = [
    adItems[0] && Ads.hooks.useIsAdRender(adItems[0]),
    adItems[1] && Ads.hooks.useIsAdRender(adItems[1]),
    adItems[2] && Ads.hooks.useIsAdRender(adItems[2]),
  ]

  const shouldShowContent = (
    columnIndex: number,
    rowIndex: number,
  ): boolean => {
    if (!isNativeAdEnabled) return true
    const adPosition = [2, 3, 2]
    if (
      isAdRender[columnIndex] === 'show' &&
      adPosition[columnIndex] === rowIndex &&
      data[columnIndex].contents.length > 3
    ) {
      return false
    }
    return true
  }

  const isShowDate = dateDisplay === 'show'

  const allContents = data.flatMap(item =>
    item.contents.flatMap(content => content.data),
  )
  const allContentsWithoutAds = allContents.filter(
    content => !('pos' in content),
  ) as BaseContentPreviewProps[]

  usePaidContentListLoaded(title, MODULE_UID, allContentsWithoutAds)

  return (
    <div data-module="highlight-contents" className={highlightContents()}>
      {data.map((item, columnIndex) => (
        <div key={columnIndex} className={highlightContents('Wrapper')}>
          {item.title && (
            <p
              className={highlightContents('Title')}
              data-testid="highlightContentsTitle"
            >
              {item.title}
            </p>
          )}

          <div className={highlightContents('ItemsWrapper')}>
            {item.contents.map((content, index) => {
              return (
                <Fragment key={`content-preview:${columnIndex}:${index}`}>
                  {index === 2 && (
                    <div className={highlightContents('DivEmpty')} />
                  )}
                  {content.type === 'ad' && content.data.pos && (
                    <Ads.Ad
                      pos={content.data.pos}
                      className={highlightContents('Content')}
                    />
                  )}
                  {content.type === 'contentPreview' &&
                    shouldShowContent(columnIndex, index) && (
                      <ContentPreview
                        {...content.data}
                        trackGtmClick={trackGtmClick}
                        className={highlightContents('Content')}
                        isShowDate={isShowDate}
                        trackGtmUid={MODULE_UID}
                      />
                    )}
                  {index === 2 && (
                    <div className={highlightContents('DivEmpty')} />
                  )}
                </Fragment>
              )
            })}
          </div>

          {item.moreContent?.title && (
            <Button
              variant="dark-outlined"
              className={highlightContents('ActionLink')}
              automationId="highlightContentsActionLink"
              url={item.moreContent.url}
              buttonLikeAnchor={true}
              trackGtmClick={trackGtmClick}
              size="medium"
            >
              {item.moreContent.title}
            </Button>
          )}
        </div>
      ))}
    </div>
  )
}
