import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, convertDateToShortDateString, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'

import { IconType } from './contentFeatured'
import { FeatureSeriesV2Props } from './type'

const contentGroupFormat = Bem('ContentGroup')

export const ContentGroup = ({
  contentGroup,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
  dateDisplay = 'show',
}: FeatureSeriesV2Props) => {
  const isShowDate = dateDisplay === 'show'
  return (
    <div data-testid="content-group" className={contentGroupFormat()}>
      {contentGroup.map((item, index) => {
        const date =
          item.contentType === 'Webinar'
            ? (item.webinarStartDate ?? '')
            : item.publishDate

        return (
          <div
            key={index}
            className={contentGroupFormat('ItemWrapper', {
              index: !index,
              content: !(
                contentGroup.length && contentGroup.length - 1 === index
              ),
            })}
          >
            {item.keyword?.title && item.keyword.url && (
              <Keyword
                variant="alternate"
                className={contentGroupFormat('Keyword')}
                url={item.keyword.url}
                title={item.keyword.title}
                trackGtmClick={trackGtmClick}
              />
            )}

            <Link
              to={item.url}
              trackGtmClick={trackGtmClick}
              className={contentGroupFormat('Title')}
              dangerouslySetInnerHTML={{
                __html: renderMarkdown(item.title),
              }}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(item, trackGtmTitle, trackGtmUid)
              }
            />
            {isShowDate && (
              <div
                className={contentGroupFormat('DateWrapper')}
                data-testid="content-group-item-date"
              >
                <span className={contentGroupFormat('PublishDate')}>
                  {convertDateToShortDateString(date)}
                </span>
                {item.contentType !== 'Article' && (
                  <>
                    <span className={contentGroupFormat('Line')}>|</span>
                    <Link
                      to={item.listPageUrl}
                      trackGtmClick={trackGtmClick}
                      title={`Link to all ${item.contentType.toLowerCase()}`}
                      className={contentGroupFormat('Link')}
                      data-testid="content-preview-icon-link"
                    >
                      <IconType
                        type={item.contentType}
                        classnames={contentGroupFormat('Icon')}
                      />
                    </Link>
                  </>
                )}
                <CardTime
                  timeRead={item.count}
                  date={date}
                  webinarStartTime={item.webinarStartTime}
                  contentType={item.contentType}
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
