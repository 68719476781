import { FC } from 'react'

import { EventType, GTM, usePaidContentListLoaded } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { FeaturedContent } from './featuredContent'
import { SupplementContent } from './supplementContent'
import { FeaturedContentHubProps } from './type'

const featuredContentHub = Bem('FeaturedContentHub')

const MODULE_UID = 'module_featured_content_hub'

export const FeaturedContentHub: FC<FeaturedContentHubProps> = props => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: props.title,
      location: props.moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  const { title, classnames, backgroundColor, button } = props
  const allItems = [props.featuredItem, ...props.supplementItems]

  usePaidContentListLoaded(title, MODULE_UID, allItems)

  return (
    <>
      <div
        data-module="featured-content-hub"
        className={
          backgroundColor
            ? featuredContentHub('Background', [classnames])
            : featuredContentHub({}, [classnames])
        }
        style={{ backgroundColor: backgroundColor }}
      >
        <div className={featuredContentHub('Header')}>
          <h3 data-testid="title" className={featuredContentHub('Title')}>
            {title}
          </h3>
          <Link
            className={featuredContentHub('Link')}
            to={button?.url || '/'}
            trackGtmClick={trackGtmClick}
            target={button?.url?.startsWith('http') ? '_blank' : '_self'}
          >
            <span
              data-testid="button"
              className={featuredContentHub('LinkText')}
            >
              {button?.text || 'More'}
            </span>
            <Icon
              icon="ChevronRight"
              className={featuredContentHub('LinkIcon')}
            />
          </Link>
        </div>

        <div className={featuredContentHub('ContentWrapper')}>
          <div
            className={featuredContentHub('Col')}
            data-testid="featured-content-hub-col"
          >
            <FeaturedContent
              {...props}
              trackGtmClick={trackGtmClick}
              trackGtmUid={MODULE_UID}
              trackGtmTitle={title}
            />
          </div>
          <div
            className={featuredContentHub('Col')}
            data-testid="featured-content-hub-col"
          >
            <SupplementContent
              {...props}
              trackGtmClick={trackGtmClick}
              trackGtmUid={MODULE_UID}
              trackGtmTitle={title}
            />
          </div>
        </div>
      </div>
    </>
  )
}
