import { EventType, GTM } from '@scm/tag-manager'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { BidsItem, CashGrainsBidsProps } from './type'

const cashGrainsBids = Bem('CashGrainsBids')

const MODULE_UID = 'module_cash_grain_bids'

export const CashGrainsBids = ({
  ctaButtonLink,
  ctaButtonText,
  name,
  city,
  state,
  bids,
  moduleLocation,
  title,
}: CashGrainsBidsProps) => {
  const sendEvent = GTM.hooks.useSendEvent()
  const url = GTM.utils.getCurrentUrl()

  const trackGtmClick = () => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(ctaButtonLink, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  const renderList = (bids: BidsItem[]) => {
    if (!bids || bids?.length <= 0) return

    return bids?.map(({ name, contract, price }, index) => {
      return (
        <li key={index}>
          <p
            className={cashGrainsBids('StatisticsItemName')}
            data-testid="bids-item-name"
          >
            {name?.toLowerCase()}
          </p>
          <p
            className={cashGrainsBids('StatisticsItemContract')}
            data-testid="bids-item-contract"
          >
            {contract}
          </p>
          <p
            className={cashGrainsBids('StatisticsItemPrice')}
            data-testid="bids-item-price"
          >
            ${price}
          </p>
        </li>
      )
    })
  }

  return (
    <div data-component="cash-grains-bids" className={cashGrainsBids()}>
      <p
        className={cashGrainsBids('Title')}
        data-testid="cash-grains-bids-title"
      >
        Cash Grain Bids for
      </p>
      <h2
        className={cashGrainsBids('Location')}
        data-testid="cash-grains-bids-location"
      >{`${city}, ${state}`}</h2>

      <div className={cashGrainsBids('ChangeLocation')}>
        <Link
          to={'/commodities/cash-grain-bids'}
          data-testid="cash-grains-bids-change-location"
        >
          Change Location
        </Link>
      </div>

      <div className={cashGrainsBids('Statistics')}>
        <h2
          className={cashGrainsBids('StatisticsTitle')}
          data-testid="cash-grains-bids-statistics-title"
        >
          {`${name} (${city}, ${state})`}
        </h2>
        <ul
          className={cashGrainsBids('StatisticsList')}
          data-testid="cash-grains-bids-statistics-list"
        >
          {renderList(bids)}
        </ul>
      </div>
      <div className={cashGrainsBids('RedirectButtonWrapper')}>
        {ctaButtonLink && (
          <Link
            className={cashGrainsBids('RedirectButton')}
            to={ctaButtonLink}
            trackGtmClick={trackGtmClick}
            data-testid="cash-grains-bids-redirect-button"
          >
            {ctaButtonText}
          </Link>
        )}
      </div>
    </div>
  )
}
