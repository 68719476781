import { Bem } from '@scm/ui-core'
import { TextBoxProps } from './type'
import { Content } from '@scm/ui-module-content'
import { Button } from '@scm/ui-component-button'
import { useGtmClick } from '@scm/tag-manager'
import { useMediaQuery } from 'react-responsive'

const textBox = Bem('TextBox')

const MODULE_UID = 'module_text_box'

export const TextBox = (props: TextBoxProps) => {
  const {
    title,
    displayTitle,
    textSectionJson,
    ctaButton: { ctaButtonText, ctaButtonUrl },
    moduleDisplayOptions: { textAlignment },
    moduleLocation,
  } = props
  const isExternalUrl = ctaButtonUrl?.startsWith('http')
  const targetUrl = isExternalUrl ? '_blank' : '_self'
  const isMobileViewPort = useMediaQuery({ maxWidth: 743 })

  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  return (
    <div className={textBox('Wrapper')} data-module="text-box">
      <div className={textBox(`Title_${textAlignment}`)}>
        <h2 className={textBox('Title')}>{displayTitle}</h2>
      </div>

      {textSectionJson.length ? (
        <div className={textBox('Content')}>
          <div className={textBox(`Content_${textAlignment}`)}>
            <Content content={textSectionJson} trackGtmClick={trackGtmClick} />
          </div>
        </div>
      ) : null}

      {(ctaButtonText || ctaButtonUrl) && (
        <div className={textBox('ButtonWrapper')}>
          <Button
            size={isMobileViewPort ? 'medium' : 'large'}
            variant="primary-filled"
            className={textBox('Button')}
            url={ctaButtonUrl}
            buttonLikeAnchor
            target={targetUrl}
            trackGtmClick={trackGtmClick}
          >
            {ctaButtonText}
          </Button>
        </div>
      )}
    </div>
  )
}
