import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { ContentType } from '@scm/ui-component-article-read-time'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, convertDateToShortDateString, renderMarkdown } from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'
import { ClassName } from '@scm/ui-tailwind'

import { FeatureSeriesV2Props } from './type'

interface IIconType {
  type: ContentType
  classnames: ClassName
}

const contentFeature = Bem('ContentFeatured')

export const ContentFeatured = ({
  contentFeatured,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
  dateDisplay = 'show',
}: FeatureSeriesV2Props) => {
  const {
    title,
    image,
    url,
    summary,
    publishDate,
    webinarStartDate,
    webinarStartTime,
    count,
    keyword,
    contentType,
    listPageUrl,
  } = contentFeatured
  const isShowDate = dateDisplay === 'show'

  const date =
    contentType === 'Webinar' ? (webinarStartDate ?? '') : publishDate

  return (
    <div data-testid="content-featured" className={contentFeature()}>
      <div className={contentFeature('ImageWrapper')}>
        <div className={contentFeature('Aspect')}>
          <Link
            to={url}
            trackGtmClick={trackGtmClick}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(
                contentFeatured,
                trackGtmTitle,
                trackGtmUid,
              )
            }
          >
            <Image
              src={image.src}
              alt={image.alt}
              fallback="brand"
              className={contentFeature('Image')}
            />
          </Link>
        </div>
        {keyword?.title && keyword.url && (
          <Keyword
            className={contentFeature('Keyword')}
            title={keyword.title}
            trackGtmClick={trackGtmClick}
            url={keyword.url}
            variant="standard"
          />
        )}
        {contentType !== 'Article' && (
          <Link
            className={contentFeature('Overlay')}
            to={listPageUrl}
            trackGtmClick={trackGtmClick}
            title={`Link to all ${contentType.toLowerCase()}`}
            data-testid="content-preview-icon-link"
          >
            <IconType type={contentType} classnames={contentFeature('Icon')} />
          </Link>
        )}
      </div>
      <Link
        to={url}
        trackGtmClick={trackGtmClick}
        className={contentFeature('Title')}
        data-testid="content-featured-title"
        dangerouslySetInnerHTML={{
          __html: renderMarkdown(title),
        }}
        trackPaidContentClick={() =>
          handlePaidContentClickEvent(
            contentFeatured,
            trackGtmTitle,
            trackGtmUid,
          )
        }
      />
      <p
        className={contentFeature('Summary')}
        data-testid="content-featured-summary"
        dangerouslySetInnerHTML={{
          __html: renderMarkdown(summary || ''),
        }}
      />
      {isShowDate && (
        <div
          className={contentFeature('Bottom')}
          data-testid="content-featured-date"
        >
          <span className={contentFeature('PublishedDate')}>
            {convertDateToShortDateString(date)}
          </span>
          <CardTime
            timeRead={count}
            date={date}
            webinarStartTime={webinarStartTime}
            contentType={contentType}
          />
        </div>
      )}
    </div>
  )
}

export const IconType = ({ type, classnames }: IIconType) => {
  switch (type) {
    case 'Document':
      return <Icon className={classnames} icon={'Document'} />
    case 'Video':
      return <Icon className={classnames} icon={'Play'} />
    case 'Webinar':
      return <Icon className={classnames} icon={'ChalkBoard'} />
    case 'Podcast':
      return <Icon className={classnames} icon={'Microphone'} />
    case 'Slideshow':
      return <Icon className={classnames} icon={'Images'} />
    default:
      return null
  }
}
