import { Fragment } from 'react'

import { Ads } from '@scm/ads'
import { EventType, GTM, usePaidContentListLoaded } from '@scm/tag-manager'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'
import {
  BaseContentPreviewProps,
  ContentPreview,
} from '@scm/ui-module-content-preview'

import { LatestFeaturedProps, ListContentProps } from './type'

const latestFeatured = Bem('LatestFeatured')

const MODULE_UID = 'module_latest_content'

export const getContentsWithoutAds = (
  contents: any[],
): BaseContentPreviewProps[] =>
  contents.filter(content => content.type !== 'ad').map(content => content.data)

export const LatestFeatured = ({
  leftColumnData,
  rightColumnData,
  displayAds,
  moduleLocation,
  title,
}: LatestFeaturedProps) => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  const mergedContents = [
    ...getContentsWithoutAds(leftColumnData.contents),
    ...getContentsWithoutAds(rightColumnData.contents),
  ]

  usePaidContentListLoaded(title, MODULE_UID, mergedContents)

  return (
    <div data-module="latest-featured" className={latestFeatured()}>
      <ListContent
        data={leftColumnData}
        isLeftColumn
        displayAds={displayAds}
        trackGtmClick={trackGtmClick}
      />
      <ListContent
        data={rightColumnData}
        isLeftColumn={false}
        displayAds={displayAds}
        trackGtmClick={trackGtmClick}
      />
    </div>
  )
}

const ListContent = ({
  data,
  isLeftColumn,
  displayAds,
  trackGtmClick,
}: ListContentProps) => {
  const checkDisplayAds =
    (isLeftColumn && displayAds.position === 'left') ||
    (!isLeftColumn && displayAds.position === 'right')
  return (
    <div
      className={latestFeatured('ColumnList', { left: isLeftColumn })}
      data-testid="list-content"
    >
      {checkDisplayAds && displayAds.pos && (
        <div
          className={latestFeatured('Ad', {
            show: Ads.hooks.useIsAdRender(displayAds.pos) == 'show',
          })}
        >
          <Ads.Ad
            pos={displayAds.pos}
            sizes={[
              [300, 100],
              [300, 250],
            ]}
            responsiveSizeMapping={[
              {
                width: 0,
                height: 0,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
              {
                width: 480,
                height: 200,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
            ]}
          />
        </div>
      )}
      <p
        className={latestFeatured('Title', {
          right: !isLeftColumn,
          withoutAd: !isLeftColumn && !checkDisplayAds,
        })}
        data-testid="list-content-title"
      >
        {data.title}
      </p>
      <div className={latestFeatured('Content', { left: isLeftColumn })}>
        {data.contents.map((content, index) => {
          return (
            <Fragment key={index}>
              {content.type === 'ad' && content.data.pos && (
                <Ads.Ad pos={content.data.pos} />
              )}

              {content.type === 'contentPreview' && (
                <ContentPreview
                  {...content.data}
                  trackGtmClick={trackGtmClick}
                  className={latestFeatured('ContentItem', {
                    left: isLeftColumn,
                  })}
                  showContributorTitle={true}
                  trackGtmUid={MODULE_UID}
                />
              )}
            </Fragment>
          )
        })}
      </div>
      {data.moreContent.title && data.moreContent.url && (
        <Link
          data-testid="list-content-more-content"
          className={latestFeatured('Link', { right: !isLeftColumn })}
          to={data.moreContent.url}
          trackGtmClick={trackGtmClick}
        >
          {data.moreContent.title}
        </Link>
      )}
    </div>
  )
}
