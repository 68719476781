import { Bem, renderMarkdown } from '@scm/ui-core'
import { DataTableProps, RootRouteData } from './type'
import { Content } from '@scm/ui-module-content'
import { Button } from '@scm/ui-component-button'
import { useGtmClick } from '@scm/tag-manager'
import { useRouteLoaderData } from '@remix-run/react'

const dataTable = Bem('DataTable')

const isInternalLink = (link: string, domainUrl: string) => {
  try {
    const url = new URL(link)
    return url.origin === domainUrl
  } catch (e) {
    return true
  }
}

const MODULE_UID = 'module_data_table'

export const DataTable = (props: DataTableProps) => {
  const {
    title,
    displayTitle,
    tableDataJson,
    ctaButton1,
    ctaButton2,
    summary,
    footnote,
    moduleLocation,
  } = props
  const { domainUrl } = useRouteLoaderData('root') as RootRouteData
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  return (
    <div className={dataTable('Wrapper')} data-module="text-box">
      <div className={dataTable(`TitleContainer`)}>
        <h2 className={dataTable('Title')}>{displayTitle}</h2>
      </div>

      {summary && (
        <p
          className={dataTable('Summary')}
          data-testid="data-table-summary"
          dangerouslySetInnerHTML={{
            __html: renderMarkdown(summary),
          }}
        />
      )}

      <div
        className={dataTable('Body')}
        data-testid="article-base-body-content"
      >
        <Content content={tableDataJson} trackGtmClick={trackGtmClick} />
      </div>

      {footnote && (
        <p
          className={dataTable('Footnote')}
          data-testid="data-table-footnote"
          dangerouslySetInnerHTML={{
            __html: renderMarkdown(footnote),
          }}
        />
      )}

      {(ctaButton1?.url || ctaButton2?.url) && (
        <div className={dataTable('ButtonsContainer')}>
          {ctaButton1?.url && (
            <div className={dataTable('ButtonWrapper')}>
              <Button
                size="large"
                variant="primary-filled"
                className={dataTable('Button', ['primary'])}
                url={ctaButton1?.url}
                buttonLikeAnchor
                target={
                  isInternalLink(ctaButton1.url, domainUrl)
                    ? undefined
                    : '_blank'
                }
                trackGtmClick={trackGtmClick}
              >
                {ctaButton1?.text || 'FULL LIST'}
              </Button>
            </div>
          )}

          {ctaButton2?.url && (
            <div className={dataTable('ButtonWrapper')}>
              <Button
                size="large"
                variant="dark-outlined"
                className={dataTable('Button', ['secondary'])}
                url={ctaButton2?.url}
                buttonLikeAnchor
                target={
                  isInternalLink(ctaButton2.url, domainUrl)
                    ? undefined
                    : '_blank'
                }
                trackGtmClick={trackGtmClick}
              >
                {ctaButton2?.text}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
