import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Contributors } from '@scm/ui-component-contributors'
import { Icon } from '@scm/ui-component-icon'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem, convertDateToShortDateString, renderMarkdown } from '@scm/ui-core'
import {
  BaseContentPreviewProps,
  getContentIconUrl,
} from '@scm/ui-module-content-preview'
import { useRef } from 'react'

const featureItem = Bem('FeatureItem')

export type FeatureItemProps = BaseContentPreviewProps & {
  isBigFeature: boolean
  isShowDate?: boolean
}

export const FeatureItem = (props: FeatureItemProps) => {
  const {
    categoryName,
    categoryUrl,
    articleName,
    contributors,
    date,
    thumbnail,
    listPageUrl,
    articleUrl,
    contentType,
    isBigFeature,
    linkAttrs,
    isShowDate,
    trackGtmClick,
    trackGtmUid,
    trackGtmTitle,
  } = props

  const linkRef = useRef<HTMLAnchorElement | null>(null)
  const icon = getContentIconUrl(contentType)
  const handleUrlClick = () => {
    linkRef.current?.click()
  }

  return (
    <div
      className={featureItem('FeatureItemWrapper', { isBigFeature })}
      onClick={handleUrlClick}
      onKeyDown={handleUrlClick}
      role="presentation"
    >
      <Link
        ref={linkRef}
        aria-label={`Read more about: ${articleName}`}
        to={articleUrl}
        trackGtmClick={trackGtmClick}
        trackPaidContentClick={() =>
          handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
        }
      />
      <div className={featureItem('HeaderContainer')}>
        {categoryName && (
          <Keyword
            className={featureItem('Keyword')}
            title={categoryName}
            url={categoryUrl}
            trackGtmClick={trackGtmClick}
            variant="standard"
          />
        )}
        {icon && (
          <Link
            to={listPageUrl}
            trackGtmClick={trackGtmClick}
            className={featureItem('LinkIcon')}
            title={`Link to all ${contentType.toLowerCase()}`}
            {...linkAttrs}
            data-testid="content-preview-icon-link"
          >
            <div className={featureItem('IconWrapper')}>
              <Icon icon={icon} className={featureItem('Icon')} />
            </div>
          </Link>
        )}
      </div>
      <div className={featureItem('FooterContainer')}>
        <h2
          className={featureItem('Title')}
          dangerouslySetInnerHTML={{ __html: renderMarkdown(articleName) }}
        />
        <div
          className={featureItem('FooterWrapper', {
            contributors: !!contributors,
          })}
        >
          {contributors && contributors.length > 0 && (
            <Contributors
              variant="slimline"
              contributors={contributors}
              trackGtmClick={trackGtmClick}
              hasAvatar={false}
              classNames={featureItem('ContributorWrapper')}
            />
          )}

          {isShowDate && (
            <p className={featureItem('Date')}>
              {convertDateToShortDateString(date)}
            </p>
          )}
        </div>
      </div>
      <div className={featureItem('ImageContainer', { isBigFeature })}>
        <Image
          loading="eager"
          src={thumbnail.src}
          alt={thumbnail.alt}
          fallback="brand"
          className={featureItem('FeatureImage')}
        />
      </div>
    </div>
  )
}
