import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import {
  Bem,
  SPONSORED_CONTENT,
  convertDateToShortDateString,
  renderMarkdown,
} from '@scm/ui-core'
import { CardTime } from '@scm/ui-module-card-time'
import { getContentIconUrl } from '@scm/ui-module-content-preview'

import { ListItemProps } from './type'

const listItem = Bem('ListItem')

export const ListItem = (props: ListItemProps) => {
  const {
    articleUrl = '',
    listPageUrl,
    title = '',
    description,
    variant,
    keyword,
    publishDate = '',
    webinarStartDate = '',
    webinarStartTime = '',
    readTime = '',
    contentType,
    isSponsored = false,
    trackGtmClick,
    trackGtmUid,
    trackGtmTitle,
  } = props
  const icon = getContentIconUrl(contentType)

  const date =
    contentType === 'Webinar' ? (webinarStartDate ?? '') : publishDate

  return (
    <div className={listItem()} data-testid="feature-list-item">
      {variant === 'variant1' && (
        <div className={listItem('VariantWrapper')}>
          {isSponsored && (
            <div className={listItem('Sponsored')}>{SPONSORED_CONTENT}</div>
          )}
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            data-testid="list-item-title"
            className={listItem('Title', { [variant]: true })}
            dangerouslySetInnerHTML={{
              __html: renderMarkdown(title),
            }}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          />
          {description && (
            <p
              className={listItem('Description')}
              data-testid="list-item-description"
              dangerouslySetInnerHTML={{
                __html: renderMarkdown(description),
              }}
            />
          )}
        </div>
      )}

      {variant === 'variant2' && (
        <div className={listItem('VariantWrapper')}>
          {keyword?.title && keyword.url && (
            <Link
              to={keyword.url}
              trackGtmClick={trackGtmClick}
              className={listItem('Keyword')}
              data-testid="item-keyword"
            >
              {keyword.title}
            </Link>
          )}
          <Link
            to={articleUrl}
            trackGtmClick={trackGtmClick}
            className={listItem('Title', { [variant]: true })}
            dangerouslySetInnerHTML={{
              __html: renderMarkdown(title),
            }}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(props, trackGtmTitle, trackGtmUid)
            }
          />
          <div className={listItem('Footer')}>
            <span className={listItem('Date')} data-testid="publish-date">
              {convertDateToShortDateString(date)}
            </span>
            {icon && (
              <>
                <span className={listItem('Separator')}>|</span>
                <Link
                  to={listPageUrl}
                  trackGtmClick={trackGtmClick}
                  title={`Link to all ${contentType.toLowerCase()}`}
                  data-testid="content-preview-icon-link"
                >
                  <Icon icon={icon} className={listItem('Icon')} />
                </Link>
              </>
            )}
            <CardTime
              timeRead={Number(readTime)}
              date={date}
              webinarStartTime={webinarStartTime}
              contentType={contentType}
            />
          </div>
        </div>
      )}
    </div>
  )
}
