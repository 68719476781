/* eslint-disable complexity */
import { CashGrainsBids } from '@scm/ui-component-cash-grains-bids'
import { Bem, Brand } from '@scm/ui-core'
import { CommodityPrices } from '@scm/ui-module-commodity-prices'
import { WeatherWidget } from '@scm/ui-module-weather-widget'

import { WeatherHomepageProps } from './type'
import { selectors } from '@scm/redux-store'

const weatherHomepage = Bem('WeatherHomepage')

export const WeatherHomepage = ({
  weatherWidget,
  cashGrainBids,
  commodityPrices,
}: WeatherHomepageProps) => {
  const { isMarketsDivision } = selectors.useConfig()
  const { brand } = selectors.useAuth()
  const {
    disableWeatherWidget,
    disableCashGrainWidget,
    disableCommoditiesWidget,
  } = selectors.useTestAutomation()

  if (
    (!isMarketsDivision && brand !== Brand.TEST_AUTOMATION) ||
    (disableWeatherWidget && disableCashGrainWidget && disableCommoditiesWidget)
  ) {
    return null
  }

  return (
    <div data-template="weather-homepage" className={weatherHomepage()}>
      <div className={weatherHomepage('Widgets')}>
        <div className={weatherHomepage('WeatherWidget')}>
          {!disableWeatherWidget &&
            weatherWidget &&
            weatherWidget.defaultValue && (
              <WeatherWidget {...weatherWidget} moduleLocation="homepage" />
            )}
        </div>
        {!disableCashGrainWidget &&
          cashGrainBids &&
          cashGrainBids.defaultZip && (
            <CashGrainsBids {...cashGrainBids} moduleLocation="homepage" />
          )}
      </div>
      {!disableCommoditiesWidget && commodityPrices && (
        <div className={weatherHomepage('CommodityPrices')}>
          <CommodityPrices {...commodityPrices} moduleLocation="homepage" />
        </div>
      )}
    </div>
  )
}
