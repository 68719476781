import { Ads } from '@scm/ads'
import {
  handlePaidContentClickEvent,
  usePaidContentListLoaded,
} from '@scm/tag-manager'
import { Button } from '@scm/ui-component-button'
import { Link } from '@scm/ui-component-link'
import { Bem, convertDateToShortDateString, renderMarkdown } from '@scm/ui-core'
import { ContentPreview } from '@scm/ui-module-content-preview'

import { TopFeaturedAndRecentProps } from '../type'

const topFeatured = Bem('TopFeatured')

export const TopFeaturedAndRecentContent = ({
  features,
  variant,
  latestNews,
  rectangleAdPos,
  recentLabel,
  ctaButton,
  ctaButtonLink,
  trackGtmClick,
  dateDisplay = 'show',
  trackGtmUid,
  title,
}: TopFeaturedAndRecentProps) => {
  const isAdsRendered = rectangleAdPos
    ? Ads.hooks.useIsAdRender(rectangleAdPos)
    : 'empty'
  // if haven't ad show 4 post
  const smallFeatures = rectangleAdPos
    ? features.slice(1, 3)
    : features.slice(1, 5)

  const allFeatures =
    features.length > 0 ? [features[0], ...smallFeatures, ...latestNews] : []

  usePaidContentListLoaded(title, trackGtmUid, allFeatures)
  const isShowDate = dateDisplay === 'show'
  return (
    <div data-module="top-featured" className={topFeatured({ variant })}>
      {features.length > 0 && (
        <>
          <div
            data-testid="latest-news-wrapper"
            className={topFeatured('WrapLatestNews')}
          >
            <div>
              <h2
                data-testid="recent-label"
                className={topFeatured('WrapLatestNewsTop')}
              >
                {recentLabel}
              </h2>
            </div>
            {latestNews?.map((post, index) => (
              <div key={index} className={topFeatured('LatestNewsItem')}>
                <div className={topFeatured('LatestNewsItemTitle')}>
                  <Link
                    to={post.articleUrl}
                    trackGtmClick={trackGtmClick}
                    trackPaidContentClick={() =>
                      handlePaidContentClickEvent(post, title, trackGtmUid)
                    }
                  >
                    <h3
                      className={topFeatured('LatestNewsItemTitle')}
                      dangerouslySetInnerHTML={{
                        __html: renderMarkdown(post.articleName),
                      }}
                    />
                  </Link>
                </div>
                {isShowDate && (
                  <div className={topFeatured('LatestNewsItemDate')}>
                    {convertDateToShortDateString(post.date)}
                  </div>
                )}
              </div>
            ))}
            {ctaButton.length > 0 && (
              <div className={topFeatured('CtaButtonWrapper')}>
                <Button
                  className={topFeatured('CtaButton')}
                  variant="text-link"
                  size="medium"
                  url={ctaButtonLink}
                  rightIcon="ChevronRight"
                  trackGtmClick={trackGtmClick}
                >
                  {ctaButton}
                </Button>
              </div>
            )}
          </div>
          <div
            data-testid="content-preview-wrapper"
            className={topFeatured('WrapContentPreview')}
          >
            {features.length > 0 ? (
              <ContentPreview
                {...features[0]}
                trackGtmClick={trackGtmClick}
                variant="top-featured"
                isShowDate={isShowDate}
                trackGtmUid={trackGtmUid}
                trackGtmTitle={title}
              />
            ) : null}
          </div>
          <div
            className={topFeatured('WrapContentPreviewHorizontal', {
              isAdsRendered,
              variant,
            })}
          >
            <div
              className={topFeatured('WrapContentPreviewHorizontal2', {
                isAdsRendered,
              })}
            >
              {smallFeatures.map((feature, index) => {
                const isLast = index === smallFeatures.length - 1
                return (
                  <ContentPreview
                    trackGtmClick={trackGtmClick}
                    className={topFeatured('ContentPreviewHorizontal', {
                      isLast,
                      variant,
                    })}
                    key={index}
                    {...feature}
                    variant="card-horizontal"
                    layoutVariant="horizontal"
                    isShowDate={isShowDate}
                    trackGtmUid={trackGtmUid}
                    trackGtmTitle={title}
                  />
                )
              })}
            </div>
            {/* rectangle ad */}
            {rectangleAdPos ? (
              <div
                data-testid="content-preview-ad-container"
                className={topFeatured('RetangleAds', {
                  isShowAd: !!isAdsRendered,
                })}
              >
                <Ads.Ad
                  pos={rectangleAdPos}
                  sizes={[
                    [300, 100],
                    [300, 250],
                  ]}
                  responsiveSizeMapping={[
                    {
                      width: 0,
                      height: 0,
                      sizes: [
                        [300, 100],
                        [300, 250],
                      ],
                    },
                    {
                      width: 480,
                      height: 200,
                      sizes: [
                        [300, 100],
                        [300, 250],
                      ],
                    },
                  ]}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  )
}
