import { FC } from 'react'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwipeCarouselProps } from './type'

export const SwipeSlide = SwiperSlide
SwipeSlide.displayName = 'SwiperSlide'

export const SwipeCarousel: FC<SwipeCarouselProps> = props => (
  <Swiper
    className="SwipeCarousel"
    data-component="swipe-carousel"
    modules={[Navigation]}
    slidesPerView="auto"
    spaceBetween={24}
    navigation
    allowSlideNext
    allowSlidePrev
    {...props}
  />
)
