import { Bem } from '@scm/ui-core'
import { SocialShare } from '@scm/ui-component-social-share'
import { HeaderProps } from './type'

const headerModule = Bem('HeaderModule')

export const Header = (props: HeaderProps) => {
  const {
    displayTitle,
    displayOptions: { imageOverlayColor, includeSocialSharing },
    headerImageGroup: { headerImage, headerImageAltText },
    socialShare,
  } = props

  return (
    <div className={headerModule({ isLight: imageOverlayColor === 'light' })}>
      <div
        className={headerModule('Wrapper')}
        style={{ backgroundImage: `url(${headerImage.url})` }}
        data-module="header"
        role="img"
        aria-label={headerImageAltText}
      >
        <h1 className={headerModule('Title')}>{displayTitle}</h1>
      </div>
      {includeSocialSharing && socialShare?.length ? (
        <div className={headerModule('SocialShare')}>
          <SocialShare
            variant="button"
            icons={socialShare}
            location="content_top"
          />
        </div>
      ) : null}
    </div>
  )
}
