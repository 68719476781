import { FC } from 'react'

import {
  handlePaidContentClickEvent,
  useGtmClick,
  usePaidContentListLoaded,
} from '@scm/tag-manager'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { VideoPlayer } from '@scm/ui-component-video-player'
import { Bem, renderMarkdown } from '@scm/ui-core'

import { VideoSpotlightProps } from './type'

const videoSpotlight = Bem('VideoSpotlight')

const MODULE_UID = 'module_video_spotlight'

export const VideoSpotlight: FC<VideoSpotlightProps> = ({
  title,
  content,
  variant,
  mode,
  moduleLocation,
}) => {
  const { heading, summary, body, videoUrl, linkAttrs, video, thumbnailImage } =
    content
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  usePaidContentListLoaded(title, MODULE_UID, [content])

  return (
    <div
      data-module="video-spotlight"
      className={videoSpotlight({
        mode,
        variant,
      })}
    >
      <article
        className={videoSpotlight('Article')}
        data-testid="video-content-container"
      >
        <div
          className={videoSpotlight('ImageContainer')}
          data-testid="video-spotlight-image-container"
        >
          <VideoPlayer
            video={video}
            thumbnailImage={thumbnailImage}
            withThumbnail={true}
          />
        </div>
        <div
          className={videoSpotlight('VideoInfoContainer')}
          data-testid="video-spotlight-info-container"
        >
          <div className={videoSpotlight('VideoHeadingWrapper')}>
            <Keyword
              variant="alternate"
              className={videoSpotlight('VideoLabel')}
              data-testid="video-spotlight-keyword"
              url="#"
              title={summary}
              disabled
              trackGtmClick={trackGtmClick}
            />
            <Link
              to={videoUrl ?? '#'}
              trackGtmClick={trackGtmClick}
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(content, title, MODULE_UID)
              }
            >
              <h2
                className={videoSpotlight('VideoHeading')}
                data-testid="video-spotlight-heading"
                dangerouslySetInnerHTML={{ __html: renderMarkdown(heading) }}
              />
            </Link>
          </div>
          <div className={videoSpotlight('VideoBodyWrapper')}>
            <Link
              to={videoUrl ?? '#'}
              trackGtmClick={trackGtmClick}
              {...linkAttrs}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(content, title, MODULE_UID)
              }
            >
              <p
                className={videoSpotlight('VideoBody')}
                data-testid="video-spotlight-body"
                dangerouslySetInnerHTML={{ __html: renderMarkdown(body) }}
              />
            </Link>
          </div>
        </div>
      </article>
    </div>
  )
}
