import { FC } from 'react'

import { EventType, GTM, usePaidContentListLoaded } from '@scm/tag-manager'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { ContentFeatured } from './contentFeatured'
import { ContentGroup } from './contentGroup'
import { FeatureSeriesV2Props } from './type'

const featureSeriesV2 = Bem('FeatureSeriesV2')

const MODULE_UID = 'module_section_promo_v2'

export const FeatureSeriesV2: FC<FeatureSeriesV2Props> = props => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: props.title,
      location: props.moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }

  const { button, title, classnames, backgroundColor } = props
  const contentItems = [props.contentFeatured, ...props.contentGroup]

  usePaidContentListLoaded(title, MODULE_UID, contentItems)

  return (
    <>
      <div
        data-module="feature-series-v2"
        className={
          backgroundColor
            ? featureSeriesV2('Background', [classnames])
            : featureSeriesV2({}, [classnames])
        }
        style={{ backgroundColor: backgroundColor }}
      >
        <div data-testid="title" className={featureSeriesV2('Title')}>
          {title}
        </div>
        <div className={featureSeriesV2('ContentWrapper')}>
          <div className={featureSeriesV2('Col')}>
            <ContentFeatured
              {...props}
              trackGtmClick={trackGtmClick}
              trackGtmUid={MODULE_UID}
              trackGtmTitle={title}
            />
          </div>
          <div className={featureSeriesV2('Col')}>
            <ContentGroup
              {...props}
              trackGtmClick={trackGtmClick}
              trackGtmUid={MODULE_UID}
              trackGtmTitle={title}
            />
          </div>
        </div>
        {button && button.text && (
          <div
            data-testid="button"
            className={featureSeriesV2('ButtonWrapper')}
          >
            <Link
              to={button.url}
              trackGtmClick={trackGtmClick}
              className={featureSeriesV2('ButtonText')}
              rel="noreferrer"
            >
              {button.text}
            </Link>
          </div>
        )}
      </div>
    </>
  )
}
