import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { FeaturedContentHubProps } from './type'

const featuredContent = Bem('FeaturedContent')

export const FeaturedContent = ({
  featuredItem,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: FeaturedContentHubProps) => {
  if (!featuredItem) {
    return null
  }
  const { title, image, url, summary, keyword } = featuredItem

  return (
    <div data-testid="featured-content" className={featuredContent()}>
      <div className={featuredContent('ImageWrapper')}>
        <div className={featuredContent('Aspect')}>
          <Link
            to={url}
            trackGtmClick={trackGtmClick}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(
                featuredItem,
                trackGtmTitle,
                trackGtmUid,
              )
            }
          >
            <Image
              src={image?.src}
              alt={image?.alt || ''}
              fallback="brand"
              className={featuredContent('Image')}
            />
          </Link>
        </div>
        {keyword?.title && keyword.url && (
          <Keyword
            className={featuredContent('Keyword')}
            title={keyword.title}
            trackGtmClick={trackGtmClick}
            url={keyword.url}
            variant="standard"
          />
        )}
      </div>
      <Link
        to={url}
        trackGtmClick={trackGtmClick}
        className={featuredContent('Title')}
        trackPaidContentClick={() =>
          handlePaidContentClickEvent(featuredItem, trackGtmTitle, trackGtmUid)
        }
      >
        {title}
      </Link>
      <p className={featuredContent('Summary')}>{summary}</p>
    </div>
  )
}
