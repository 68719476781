import { useGtmClick, usePaidContentListLoaded } from '@scm/tag-manager'
import { useRef } from 'react'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Bem, renderMarkdown } from '@scm/ui-core'
import { ContentPreview } from '@scm/ui-module-content-preview'
import { SwipeCarousel, SwipeSlide } from '@scm/ui-component-swipe-carousel'
import { Button } from '@scm/ui-component-button'

import { ContentRibbonProps } from './type'

const contentRibbon = Bem('ContentRibbon')

const MODULE_UID = 'module_content_ribbon'

export const ContentRibbon = ({
  title,
  moduleLabel = '',
  link,
  contents,
  dateDisplay,
  contributorDisplay,
  moduleLocation = 'modular_program',
}: ContentRibbonProps) => {
  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)
  const { href, label } = link || {}
  const showLink = href && label

  const mappedContent = contents.map(content => content.data)

  usePaidContentListLoaded(title, MODULE_UID, mappedContent)

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <div data-module="content-ribbon" className={contentRibbon()}>
      <div
        className={contentRibbon('Header')}
        data-testid="content-ribbon-header"
      >
        <h3
          className={contentRibbon('Title')}
          data-testid="content-ribbon-title"
          dangerouslySetInnerHTML={{
            __html: renderMarkdown(moduleLabel || title),
          }}
        />
        {showLink && (
          <Link
            className={contentRibbon('Link')}
            data-testid="content-ribbon-link"
            to={href}
            trackGtmClick={trackGtmClick}
          >
            <span
              className={contentRibbon('LinkText')}
              data-testid="content-ribbon-link-title"
            >
              {label}
            </span>
            <Icon icon="ChevronRight" className={contentRibbon('LinkIcon')} />
          </Link>
        )}
      </div>
      <div className={contentRibbon('Content')}>
        <SwipeCarousel
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
        >
          {contents.map((item, index) => (
            <SwipeSlide key={index}>
              <ContentPreview
                {...item.data}
                variant="card-vertical"
                isShowDate={dateDisplay === 'show'}
                isShowContributor={contributorDisplay === 'show'}
                isShowCategory={false}
                isShowSponsoredContent={true}
                trackGtmClick={trackGtmClick}
                trackGtmUid={MODULE_UID}
              />
            </SwipeSlide>
          ))}
          <div ref={prevRef} className="swiper-button-prev">
            <Icon icon="ChevronLeft" className="ArrowLeft" />
          </div>
          <div ref={nextRef} className="swiper-button-next">
            <Icon icon="ChevronRight" className="ArrowRight" />
          </div>
        </SwipeCarousel>
      </div>

      {showLink && (
        <Button
          variant="dark-outlined"
          size="medium"
          url={href}
          trackGtmClick={trackGtmClick}
          data-testid="content-ribbon-cta-button"
        >
          {label}
        </Button>
      )}
    </div>
  )
}
