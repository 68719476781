import { usePaidContentListLoaded } from '@scm/tag-manager'
import { Bem } from '@scm/ui-core'

import { BaseFeaturedInterface } from '../type'
import { FeatureItem } from './featureItem'

const topFeatured = Bem('TopFeatured')

export const TopFeaturedDefault = ({
  features,
  variant,
  trackGtmClick,
  dateDisplay = 'show',
  trackGtmUid,
  title,
}: BaseFeaturedInterface) => {
  const isShowDate = dateDisplay === 'show'

  const smallFeatures = features.slice(1, 3)

  const allFeatures = features.length > 0 ? [features[0], ...smallFeatures] : []

  usePaidContentListLoaded(title, trackGtmUid, allFeatures)

  return (
    <div data-module="top-featured" className={topFeatured({ variant })}>
      {features.length > 0 && (
        <>
          <div className={topFeatured('FeatureItem')}>
            <FeatureItem
              {...features[0]}
              isBigFeature
              trackGtmClick={trackGtmClick}
              isShowDate={isShowDate}
              trackGtmUid={trackGtmUid}
              trackGtmTitle={title}
            />
          </div>
          <div className={topFeatured('FeatureItems')}>
            {smallFeatures.map((feature, index) => (
              <FeatureItem
                key={index}
                {...feature}
                isBigFeature={false}
                trackGtmClick={trackGtmClick}
                isShowDate={isShowDate}
                trackGtmUid={trackGtmUid}
                trackGtmTitle={title}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}
