import { handlePaidContentClickEvent } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Keyword } from '@scm/ui-component-keyword'
import { Link } from '@scm/ui-component-link'
import { Bem } from '@scm/ui-core'

import { FeaturedContentHubProps } from './type'

const supplementContentFormat = Bem('SupplementContent')

export const SupplementContent = ({
  supplementItems,
  trackGtmClick,
  trackGtmUid,
  trackGtmTitle,
}: FeaturedContentHubProps) => {
  if (!supplementItems) {
    return null
  }
  return (
    <div data-testid="supplement-content" className={supplementContentFormat()}>
      {supplementItems.map((item, index) => (
        <div
          key={index}
          className={supplementContentFormat('ItemWrapper', {
            content: !(
              supplementItems.length && supplementItems.length - 1 === index
            ),
          })}
          data-testid="supplement-item-wrapper"
        >
          <Link
            to={item.url}
            trackGtmClick={trackGtmClick}
            className={supplementContentFormat('ImageLink')}
            trackPaidContentClick={() =>
              handlePaidContentClickEvent(item, trackGtmTitle, trackGtmUid)
            }
          >
            <Image
              src={item?.image?.src}
              alt={item?.image?.alt || ''}
              fallback="brand"
              className={supplementContentFormat('Image')}
            />
          </Link>

          <div className={supplementContentFormat('TextPreview')}>
            {item.keyword?.title && item.keyword.url && (
              <Keyword
                variant="alternate"
                className={supplementContentFormat('Keyword')}
                url={item.keyword.url}
                title={item.keyword.title}
                trackGtmClick={trackGtmClick}
              />
            )}
            <Link
              to={item.url}
              trackGtmClick={trackGtmClick}
              className={supplementContentFormat('Title')}
              trackPaidContentClick={() =>
                handlePaidContentClickEvent(item, trackGtmTitle, trackGtmUid)
              }
            >
              {item.title}
            </Link>
            <p className={supplementContentFormat('Summary')}>{item.summary}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
