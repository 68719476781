import { Bem } from '@scm/ui-core'
import { PullQuoteProps } from './type'
import { Button } from '@scm/ui-component-button'
import { useMediaQuery } from 'react-responsive'
import { useGtmClick } from '@scm/tag-manager'

const pullQuote = Bem('PullQuote')

const MODULE_UID = 'module_pull_quote'

export const PullQuote = (props: PullQuoteProps) => {
  const {
    title,
    moduleLocation,
    quoteText,
    byline,
    ctaButton,
    displayOptions: { darkLightToggle, backgroundImage },
  } = props

  const { trackGtmClick } = useGtmClick(title, moduleLocation, MODULE_UID)

  const isTabletViewPort = useMediaQuery({ maxWidth: 1024 })
  const isDarkMode = darkLightToggle === 'dark'
  const isExternalUrl = ctaButton.url?.startsWith('http')
  const targetUrl = isExternalUrl ? '_blank' : '_self'

  const bgOverlayColors = {
    light: 'rgba(255,255,255,.7)',
    dark: 'rgba(0,0,0,.5)',
  }

  const backgroundStyles = backgroundImage?.url
    ? {
        backgroundImage: `url(${backgroundImage.url})`,
        backgroundColor: bgOverlayColors[darkLightToggle],
      }
    : undefined

  return (
    <div
      className={pullQuote('Wrapper', [darkLightToggle])}
      style={backgroundStyles}
      data-module="pull-quote"
    >
      <div
        className={pullQuote('Overlay', [
          backgroundImage?.url ? 'WithImage' : undefined,
        ])}
      />
      <div className={pullQuote('Content')}>
        <p
          className={pullQuote('Quote', [
            backgroundImage?.url ? 'QuoteWithImage' : undefined,
          ])}
        >
          {quoteText}
        </p>
        {byline && (
          <p
            className={pullQuote('Byline', [
              backgroundImage?.url ? 'BylineWithImage' : undefined,
            ])}
          >
            - {byline}
          </p>
        )}

        {(ctaButton.text || ctaButton.url) && (
          <div className={pullQuote('ButtonWrapper')}>
            <Button
              size={isTabletViewPort ? 'medium' : 'large'}
              variant={isDarkMode ? 'secondary-filled' : 'primary-filled'}
              className={pullQuote('Button')}
              trackGtmClick={trackGtmClick}
              url={ctaButton.url}
              buttonLikeAnchor
              target={targetUrl}
            >
              {ctaButton.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
