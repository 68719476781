import { Ads } from '@scm/ads'
import { usePaidContentListLoaded } from '@scm/tag-manager'
import { Bem } from '@scm/ui-core'
import { ContentPreview } from '@scm/ui-module-content-preview'

import { BaseFeaturedInterface } from '../type'

const topFeatured = Bem('TopFeatured')

export const TopFeaturedWithAds = ({
  features,
  variant,
  rectangleAdPos,
  trackGtmClick,
  dateDisplay = 'show',
  trackGtmUid,
  title,
}: BaseFeaturedInterface) => {
  const isAdsRendered = rectangleAdPos
    ? Ads.hooks.useIsAdRender(rectangleAdPos)
    : 'empty'
  const smallFeatures = features.slice(1, 3)
  const isShowDate = dateDisplay === 'show'

  const allFeatures = features.length > 0 ? [features[0], ...smallFeatures] : []

  usePaidContentListLoaded(title, trackGtmUid, allFeatures)

  return (
    <div data-module="top-featured" className={topFeatured({ variant })}>
      <div className={topFeatured('FeatureItemsWrapper')}>
        {features.length > 0 && (
          <>
            <div className={topFeatured('FeatureItem')}>
              <ContentPreview
                {...features[0]}
                trackGtmClick={trackGtmClick}
                variant="summary"
                isShowDate={isShowDate}
                trackGtmUid={trackGtmUid}
                trackGtmTitle={title}
              />
            </div>
            {smallFeatures.length > 1 && (
              <div className={topFeatured('Content', { latest: true })}>
                {smallFeatures.length > 1 && (
                  <div className={topFeatured('FeatureItems')}>
                    <ContentPreview
                      {...features[1]}
                      trackGtmClick={trackGtmClick}
                      variant="normal"
                      articleSize="big"
                      isShowDate={isShowDate}
                      trackGtmUid={trackGtmUid}
                      trackGtmTitle={title}
                    />
                    <ContentPreview
                      {...features[2]}
                      trackGtmClick={trackGtmClick}
                      variant="normal"
                      articleSize="big"
                      isShowDate={isShowDate}
                      trackGtmUid={trackGtmUid}
                      trackGtmTitle={title}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {/* rectangle ad */}
      {rectangleAdPos ? (
        <div
          data-testid="content-preview-ad-container"
          className={topFeatured('RetangleAds', {
            isShowAd: !!isAdsRendered,
          })}
        >
          <Ads.Ad pos="resource_v" />
          <Ads.Ad
            pos={rectangleAdPos}
            sizes={[
              [300, 100],
              [300, 250],
            ]}
            responsiveSizeMapping={[
              {
                width: 0,
                height: 0,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
              {
                width: 480,
                height: 200,
                sizes: [
                  [300, 100],
                  [300, 250],
                ],
              },
            ]}
          />
        </div>
      ) : null}
    </div>
  )
}
