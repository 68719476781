import { EventType, GTM, usePaidContentListLoaded } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'
import { Bem, getStyleBackgroundImage } from '@scm/ui-core'
import { ListItem } from './listItem'
import { FeatureSeriesProps } from './type'

const featureSeries = Bem('FeatureSeries')

const MODULE_UID = 'module_section_promo'

export const FeatureSeries = ({
  backgroundImage,
  logoSrc,
  logoAlt,
  description,
  btnCTAText,
  btnCTALink = '',
  btnCoverageText,
  btnCoverageLink = '',
  logoPosition,
  listItem,
  variant,
  contentType,
  moduleLocation,
  title,
}: FeatureSeriesProps) => {
  const url = GTM.utils.getCurrentUrl()
  const sendEvent = GTM.hooks.useSendEvent()

  const trackGtmClick = (href: string) => {
    if (!url) return
    const payload = {
      name: title,
      location: moduleLocation,
      section: MODULE_UID,
      referring_page: url.pathname,
      destination_page: GTM.utils.prepareDestinationPath(href, url),
    }
    sendEvent({
      data: payload,
      event: EventType.NAVIGATION,
      _clear: true,
    })
  }
  usePaidContentListLoaded(title, MODULE_UID, listItem)

  const logoSection = (
    <div className={featureSeries('LogoSectionRoot')}>
      <div className={featureSeries('LogoSection')}>
        <div className={featureSeries('LogoWidth')}>
          <div className={featureSeries('LogoAspect')}>
            <Link
              to={btnCTALink}
              trackGtmClick={trackGtmClick}
              className={featureSeries('LogoLink')}
            >
              <Image
                src={logoSrc}
                alt={logoAlt}
                className={featureSeries('Logo')}
                width={800}
                fallback={'brand'}
              />
            </Link>
          </div>
        </div>
        <div className={featureSeries('LogoSectionContent')}>
          <p
            className={featureSeries('Description')}
            data-testid="logo-description"
          >
            {description}
          </p>

          <div className={featureSeries('ActionsWrapper')}>
            {btnCTAText && (
              <Link
                to={btnCTALink}
                trackGtmClick={trackGtmClick}
                className={featureSeries('CTA')}
                rel="noreferrer"
                data-testid="cta-button"
              >
                {btnCTAText}
              </Link>
            )}

            {btnCoverageText && (
              <Link
                to={btnCoverageLink}
                trackGtmClick={trackGtmClick}
                className={featureSeries('Coverage')}
                rel="noreferrer"
                data-testid="coverage-button"
              >
                {btnCoverageText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  const listSection = listItem && (
    <div className={featureSeries('Content')}>
      <div
        className={featureSeries('ContentList', {
          withBackground: backgroundImage,
        })}
      >
        {listItem.map((data, index) => (
          <ListItem
            key={index}
            articleUrl={data.articleUrl}
            listPageUrl={data.listPageUrl}
            title={data.title}
            description={data.description}
            variant={variant}
            keyword={data.keyword}
            publishDate={data.publishDate}
            webinarStartDate={data.webinarStartDate}
            webinarStartTime={data.webinarStartTime}
            readTime={data.readTime}
            contentType={contentType ?? data.contentType}
            isSponsored={data.isSponsored}
            trackGtmClick={trackGtmClick}
            trackGtmUid={MODULE_UID}
            trackGtmTitle={title}
          />
        ))}
      </div>
    </div>
  )
  const styleImage = getStyleBackgroundImage(backgroundImage, {
    width: '800',
    format: 'png',
    auto: 'webp',
  })
  return (
    <div
      data-module="feature-series"
      className={featureSeries()}
      style={styleImage}
    >
      {logoPosition === 'Left' && logoSection}
      {listSection}
      {logoPosition === 'Right' && logoSection}
    </div>
  )
}
